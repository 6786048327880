import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/common/Layout";
import Meta from "../components/common/Meta";

const CatalogThankYouPage = () => {
  const meta = {
    title: "資料ダウンロードありがとうございます｜（株）Digital Stacks",
    description:
      "資料ダウンロードは下記フォームにご記入いただき、 次のページで資料を選択してください。Digital Stacksが提供する製品・サービスは、法人のお客様を対象としております。",
    pathname: "/brochure-jp-thank-you/",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <div className="flex justify-center items-center px-4 flex-col mt-16 mb-20">
        <h1 className="font-sub font-bold text-center text-[28px] md:text-[32px] leading-[37.5px] mb-4 text-dsc-title max-w-[733px] mx-auto">
          デジタルトランスフォーメーションカタログ資料のダウンロードありがとうございました
        </h1>
        <p className="text-sm leading-4 tracking-[0.03] text-dsc-body text-center mb-10">
          下記の表紙画像をクリックしてダウンロードしてください。
        </p>
        <a href="/files/TechCatalog2022.pdf" download>
          <StaticImage
            src="../../static/images/catalog-download.jpg"
            alt="Catalog Download Image"
            objectFit="contain"
            width={210}
            height={300}
          />
        </a>
      </div>
    </Layout>
  );
};

export default CatalogThankYouPage;
